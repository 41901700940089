import React from 'react';

import iconMore from './icon-arrow-more.svg';
import slide from './slide.svg';

function Header( {logo} ) {

  return (
    <header className='header'>
      <div className='header-top-panel'>
        <div className='top'>      
          <span className='logo'>
            <img src={ logo } alt="Сота технологии | IT аутсорсинг" />
          </span>
          <nav className='nav'>
            <ul>
              <li><a href='1'>Услуги</a></li>
              <li><a href='2'>О Компании</a></li>
              <li><a href='3'>Контакты</a></li>
            </ul>
          </nav>
        </div>       
      </div>     

      <div className='header-wrap'>       
        <div className='header-title'>
          <h1>Проектирование и построение<br /> IT-инфраструктуры</h1>
          <span>Российская ИТ-компания, работающая в сфере разработки, системной интеграции, инфраструктурных решений, сервисных услуг и аутсорсинга, информационной безопасности, консалтинга.</span> 
        </div>
        <img src={ slide } className='slide' alt="Сота технологии | IT аутсорсинг" />
      </div>
      <span className='iconMore'>
        <img src={ iconMore } alt="More" />
      </span>
    </header>    
  )  
}

export default Header;