const dataStore = {
  services: [{
      id: 's1',
      title: 'Обслуживание компьютеров в организацияx',
      description: 'Обеспечиваем бесперебойную работу IT-инфраструктуры в режиме 24/7. Абонентское обслуживание компьютеров у нас - это поддержание непрерывной работоспособности ИТ-инфраструктуры. Оперативная диагностика и ремонт с предоставлением запасных частей.Мониторинг состояния оборудования и работы по предупреждению поломок.',
      list: [
        'Инсталляция, перемещение, модернизация рабочих мест',
        'Аппаратное обслуживание',
        'Обслуживание программного обеспечения',
        'Устранение сбоев в работе',
        'Подключение, настройка периферийных устройств',
        'Ремонт оборудования',
        'Консультация пользователя',
        'Удалённое администрирование'
      ],
      icon: 'service-comp.svg'
    },
    {
      id: 's2',
      title: 'Обслуживание серверов',
      description: 'Обеспечиваем бесперебойную работу IT-инфраструктуры.Техническое обслуживание серверов, осуществление профилактических работ, установка обновлений и приложений, резервное копирование данных и восстановление в случае неисправности. Мониторинг серверов 24/7.',
      list: [
        'Инсталляция, перемещение, серверных станций',
        'Аппаратное обслуживание',
        'Обслуживание программного обеспечения',
        'Устранение сбоев в работе',
        'Аппаратное обслуживание и настройка ИБП',
        'Работа с провайдерами по ликвидации неисправностей.'
      ],
      icon: 'service-server.svg'
    },
    {
      id: 's3',
      title: 'Инвентаризация компьютерного парка',
      description: 'Обеспечиваем бесперебойную работу IT-инфраструктуры.Техническое обслуживание серверов, осуществление профилактических работ, установка обновлений и приложений, резервное копирование данных и восстановление в случае неисправности. Мониторинг серверов 24/7.',
      list: [
        'Составление бланка инвентаризационной ведомости',
        'Выезд инженера и заполнение инвентаризационной ведомости',
        'Приведение оборудования и ПО к единому стандарту',
        'Предоставление сводной по итоговой',
        'Составление реестра неисправностей выявленных при инвентаризации'
      ],
      icon: 'service-inventory.svg'
    },
    {
      id: 's4',
      title: 'Видеонаблюдение',
      description: 'Монтаж и обслуживание аналогового и ip-видеонаблюдения. Подбор специализированного оборудования, последующее гарантийное и постгарантийное обслуживание а также:',
      list: [
        'Подбор оборудования по ТЗ Заказчика',
        'Выезд на объект и составление сметы по ТЗ Заказчика',
        'Закупка и доставка необходимых расходных материалов',
        'Монтажные работы',
        'Подключение и настройка оборудования',
        'Тестирование оборудования',
        'Фотоотчёт о проделанной работе',
        'Гарантия на работу'
      ],
      icon: 'service-cctv.svg'
    },
    {
      id: 's5',
      title: 'Аутсорсинг печати',
      description: 'Аутсорсинг печати у нас - это подбор, установка, настройка печатающего оборудования в соответствии с вашими требованиями и обеспечение его бесперебойной работы. Ремонт, расходные материалы (картриджи и тд.) входят в стоимость копии а также:',
      list: [
        'Инсталляция, логистика, модернизация',
        'Поставка расходных материалов',
        'Ремонт оборудования, включая запасные части',
        'Профилактическое обслуживание',
        'Утилизация отработанных расходных',
        'Управление печатью',
        'Консультация пользователя',
        'Ежемесячные отчёты'
      ],
      icon: 'service-print.svg'
    },
    {
      id: 's6',
      title: 'Монтаж СКС и развертывание "бесшовного" Wi-Fi',
      description: 'Наши высококвалифицированные специалисты произведут монтаж и обслуживание СКС, в которую могут входить сети ЛВС, УАТС, контроль доступа, видеонаблюдение, согласно потребностям Заказчика с использованием качественных материалов мы осуществим:',
      list: [
        'Проектирование СКС, ЛВС',
        'Монтаж и настройка бесшовного WIFI',
        'Обеспечение информационной безопасности сетей',
        'Монтаж сетевого оборудования и прокладка кабелей',
        'Выезд на объект и составление сметы по ТЗ Заказчика',
        'Закупка и доставка необходимых расходных материалов',
        'Монтажные работы',
        'Подключение и настройка оборудования',
        'Тестирование сети',
        'Гарантия на работу'
      ],
      icon: 'service-sks.svg'
    },
    {
      id: 's7',
      title: 'IP телефония',
      description: '',
      list: [
        'Проектирование сетей для организации IP телефонии',
        'Облачная АТС собственной разработки',
        'Прокладка кабельных трасс и монтаж оборудования',
        'Администрирование систем IP телефонии',
        'Сокращение расходов на телефонную связь',
        'Поставка оборудования и программного обеспечения'
      ],
      icon: 'service-ip.svg'
    }
  ]
}

export default dataStore;