import React from 'react';

import ServiceItem from './service-item';

function Service( {servicesList} ) {

  const els = servicesList.map((el) => {

    const {title, description, list, id} = el;

    return (
      <ServiceItem title={ title } desc={ description } list={list} id={ id } key={ id }/>
    )

  })

  return (
    <div className='service'>
      <div className='wrap-content'>
        <div className='service-title'>
          <span>IT Аутсорсинг</span>
          <h2>Услуги</h2>
        </div>
        <div className='services-list'>
          {els}
        </div>
      </div>   
    </div>    
  )  
}

export default Service;