import React from 'react';


function Footer( {logo} ) {

  return (
    <footer className='footer'>
      <div className='footer-wrap'>
        <div className='col-2'>
          <span className='logo'>
              <img src={ logo } alt="Сота технологии | IT аутсорсинг" />
          </span>
        </div>
        <div className='col-2'>
          <div className='col-2 inner'>
            <span className='phone'><a className="phoneLink" href="tel:+79252447763">+7 (925) 244 77 63</a></span>
            <span><i>Время работы:</i></span>
            <span className='time'>Пн-пт, с 9:00 до 20:00</span>
          </div>
          <div className='col-2 inner'>
            <span>г. Москва,<br />Хлебозаводский проезд 7а,</span>
            <span className='email'><i>Почта:</i> <a className="emailLink" href="mailto:info@sotatech.ru">info@sotatech.ru</a></span>
          </div>
        </div>
      </div>   
    </footer>    
  )  
}

export default Footer;