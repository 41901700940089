import React from 'react';

function About() {

  return (
    <div className='about'>
      <div className='wrap-content'>
        <div className='about__title'>
          <span>Компания</span>
          <h2>СотаТехнологии</h2>
        </div>
        <div className='about__text'>
          <div>
            <p>Мультибрендовый подход позволяет компании создавать индивидуальные комплексные решения для каждого заказчика практически в любой сфере информатизации бизнеса.</p>
            <p>Основные направления деятельности: проектирование и построение ИТ-инфраструктуры; разработка сложных ИТ-решений и программного обеспечения; внедрение информационных систем и бизнес-приложений; ИТ-аутсорсинг и техническая поддержка.</p>
          </div>
          <div>
            <p>Накопленный опыт и база технологических знаний позволяют «Сота Технологии» выполнять уникальные проекты, в том числе на международном уровне, выступать для клиентов в качестве единого поставщика всего спектра ИТ-услуг, сокращая затраты на трансформацию ИТ-инфраструктуры и развитие информационных систем.</p>
          </div>
          </div>
      </div>   
    </div>    
  )  
}

export default About;