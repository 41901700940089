import React from 'react';

function Service( {title, desc, list, id} ) {

  const wrapList = list.map((el, index) => {

    const key = `${id}-${index}`;  

    return <li key={key}>{el}</li>    
  })

  return (
    <div className='service-item'>
      <div className='col-2'>
        <h3 className='item-title'>{title}</h3>
        <span className='item-description'>{desc}</span>    
      </div>
      <div className='col-2'>
        <ul>         
          {wrapList}
        </ul>
      </div>          
    </div>    
  )  
}

export default Service;