import React from 'react';

import Header from '../header';
import About from '../about';
import Service from '../service';
import Footer from '../footer';

import logo from '../app/logo.svg';

import './style.scss';

function App( {dataStore} ) {

  const {services} = dataStore;

  return (
    <div className='container'>
      <Header logo={ logo }/>
      <About />
      <Service servicesList={ services }/>
      <Footer logo={ logo }/>
    </div>
  )  
}

export default App;